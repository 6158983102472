import { useState, useEffect } from "preact/hooks";
import { Config } from "../../utils/types.ts";

interface ServerConfigFormProps {
  serverId: string; // The serverId will be passed to fetch the correct configuration
}

interface InputFieldProps {
  label: string;
  name: keyof Config;
  value: string | number | boolean;
  onChange: (e: Event) => void;
  type?: string;
  checked?: boolean;
}

const configFields = [
  { label: "Client ID", name: "clientId" },
  { label: "Client Secret", name: "clientSecret" },
  { label: "Scope", name: "scope" },
  { label: "Redirect URI", name: "redirectUri" },
  { label: "Auth Server URL", name: "authServerUrl" },
  { label: "FHIR Server URL", name: "fhirServerUrl" },
  { label: "Valid Patient ID", name: "validPatientId" },
  { label: "Writable Patient ID", name: "writableValidPatientId" },
  { label: "Default Page Size", name: "defaultPageSize", type: "number" },
  { label: "Pagination Supported", name: "paginationSupported", type: "checkbox" },
  { label: "XML Supported", name: "xmlSupported", type: "checkbox" },
  { label: "Turtle Supported", name: "turtleSupported", type: "checkbox" },
  { label: "Client-Defined IDs Allowed", name: "clientDefinedIdsAllowed", type: "checkbox" },
  { label: "Referential Integrity Supported", name: "referentialIntegritySupported", type: "checkbox" },
  { label: "References Are Version-Specific", name: "referencesAreVersionSpecific", type: "checkbox" },
  { label: "HTTP Supported", name: "httpSupported", type: "checkbox" },
  { label: "Authorized", name: "authorized", type: "checkbox" }, // Authorized field added here
  { label: "User Name", name: "userName" },
  { label: "Password", name: "password", type: "password" },
];

export default function ServerConfigForm({ serverId }: ServerConfigFormProps) {
  const [formData, setFormData] = useState<Config | null>(null);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Fetch the configuration for the specific serverId
  useEffect(() => {
    async function fetchConfig() {
      if (!serverId) return; // Exit if no serverId is provided
      setLoadingConfig(true);
      setError(null);

      try {
        const res = await fetch(`/api/load-config?serverId=${serverId}`);
        if (!res.ok) throw new Error(`Failed to load config: ${res.statusText}`);
        const configData = await res.json();
        console.log("Loaded config:", configData);  // Debugging output
        setFormData(configData.config);
      } catch (err) {
        setError(`Error loading config: ${err.message}`);
      } finally {
        setLoadingConfig(false);
      }
    }

    fetchConfig(); // Fetch the configuration when serverId changes
  }, [serverId]);

  // Handle form field changes
  const handleConfigChange = (e: Event) => {
    const { name, value, type, checked } = e.currentTarget as HTMLInputElement;
    setFormData((prev) => {
      if (!prev) return prev; // Do nothing if there's no form data
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value, // Update either boolean or text fields
      };
    });
  };

  // Handle form submission to save the config
  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    if (!formData) return; // Do nothing if formData is empty
    setError(null);

    try {
      const res = await fetch("/api/save-config", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          serverId,
          config: formData, // Send the updated configuration
        }),
      });
      if (!res.ok) throw new Error("Failed to save config");
      console.log("Config saved successfully for serverId:", serverId);
    } catch (err) {
      setError(`Error saving config: ${err.message}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} class="space-y-4 p-4 border rounded shadow bg-white">
      <h2 class="text-2xl font-bold mb-4">Edit Server Configuration</h2>

      {loadingConfig ? (
        <p>Loading configuration...</p>
      ) : error ? (
        <p class="text-red-600">{error}</p>
      ) : formData ? (
        <div class="grid gap-2 md:grid-cols-2">
          {configFields.map(({ label, name, type = "text" }) => (
            <InputField
              key={name}
              label={label}
              name={name as keyof Config}
              value={formData[name as keyof Config]}
              onChange={handleConfigChange}
              type={type}
              checked={type === "checkbox" ? formData[name as keyof Config] as boolean : undefined}
            />
          ))}
        </div>
      ) : (
        <p>No configuration data available.</p>
      )}

      <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded-md mt-4">
        Save Changes
      </button>
    </form>
  );
}

// InputField component
const InputField = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  checked,
}: InputFieldProps) => (
  <div>
    <label class="block text-sm font-medium">{label}</label>
    <input
      type={type}
      name={name}
      value={typeof value === "boolean" ? String(value) : value}
      onChange={onChange}
      class="w-full p-2 border rounded-md"
      checked={checked}
    />
  </div>
);
